<template>
<!-- 登录 -->
  <div class="login_back">
    <img src="../../assets/login.png" style="width: 100vw;height: 650px;margin-top: 80px">
    <div class="login_div">
      <h3>派单系统</h3>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm">
        <el-form-item prop="account" style="margin-bottom:35px">
          <el-input
            v-model="ruleForm.account"
            placeholder="账号"
            autocomplete="off"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" style="margin-bottom:35px">
          <el-input
            show-password
            v-model="ruleForm.password"
            placeholder="密码"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
<!--        <el-form-item  prop="code">
          <el-col :span="15">
            <el-input v-model="ruleForm.code"  placeholder="验证码" maxlength="5" autocomplete="off" @keyup.enter.native="subMit('ruleForm')"></el-input>
          </el-col>
          <el-col :span="9">
              <img style="width: 90%;height:40px;margin-left: 10%;" :src="imageSrc" @click="getCode"/>
          </el-col>
        </el-form-item>-->
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="subMit('ruleForm')">立即登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>


<script src="https://pv.sohu.com/cityjson?ie=utf-8"></script>
<script>
import  {uuid}  from 'vue-uuid';
let uuidR;
export default {
  data() {
    return {
      ruleForm: {
        account: "",
        password: "",
        code:"",
        clientUid:"",
        ip: ''
      },
      imageSrc:"",
      rules: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.ruleForm.ip=returnCitySN['cip'];
    this.getCode()
  },
  methods: {
    getCode(){
      uuidR = uuid.v1();
      this.imageSrc="/api/v1/web/user-info/get-verification?clientUid="+uuidR
    },
    subMit(formName){
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          this.ruleForm.clientUid = uuidR
          this.axios({
            method: "post",
            url: "/v1/web/user-info/login",
            data: this.ruleForm,
          }).then((res) => {
            if (res.data.code === 200) {
              sessionStorage.setItem("token", res.headers.token)
              sessionStorage.setItem("userName", res.data.data.userName);
              sessionStorage.setItem("roleList", JSON.stringify(res.data.data.roleList));
              sessionStorage.setItem("permissionList", JSON.stringify(res.data.data.permissionList));
              sessionStorage.setItem("userId", res.data.data.id);
              this.$router.push("/home-admin/home-page");
            } else {
              this.$message({
                type: "error",
                message: res.data.message,
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style>
.login_div {
  width: 260px;
  background-color: #ffffff;
  margin: 280px auto;
  padding: 15px 35px 10px 35px;
  border-radius: 10px;
  position: absolute;
  top: -50px;
  right: 300px;
}
.login_back {
  background-color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

h3 {
  text-align: center;
  margin-bottom: 30px;
}
</style>
